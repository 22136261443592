<template>
  <RouterLink to="/projects/">
    <h2> All Projects </h2>
  </RouterLink>
  <div class="d-flex justify-content-center align-items-center proj-div">
    <section class="scroll-container">
      <ProjectCard
        v-for="item in showcase"
        v-bind:key="item.id"
        :id="item.id"
        :title="item.title"
        :description="item.description"
        :img="item.thumbnail"
        :techStack="item.tech_stack"
      />
    </section>
  </div>
</template>

<script setup>
import ProjectCard from "../components/ProjectCard.vue";
import projects from "../content/Projects";
import { RouterLink } from "vue-router";

const showcase = projects
</script>

<style scoped>
h2 {
  display: block;
  color: var(--secondary-color);
  text-align: center;
  box-sizing: border-box;
  text-decoration: underline;
  /*border-bottom: 2px solid var(--secondary-color);*/
}

a{text-decoration: none;
}

h2:hover{
  color: var(--tertiary-color);
  /*border-bottom: 2px solid var(--tertiary-color);*/
}

.proj-div {
  max-width: 100%;
  width: 100%;
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
  height: 30em;
  min-width: 100%;
  scrollbar-width: thin;
}
</style>
