<template>
<p>Student Lounge is a full-stack application that serves as a place for students to help each other and share precious course material. 
I built this project with my colleague Kamal during our 3rd and final year of our bachelor's degree in software development.
</p>
<p>
Not only did we write the code for a web client, a cross-platform mobile app and a server with a REST API, but we also were in charge of providing the analysis, mockups
as well as the planning of the deadlines and the tasks of this project, and finally the deployment of the whole service. 
</p>
<p>
This is the pinnacle of our 3 years in software development, we managed to carry out each step of the development of a software project and build something that we truly are proud of.
</p>
<p>
While developing, we learned learned how to integrate widely used web services such as Firebase, for authentication, or the Google Maps & Places API, to guide users to the rally point of a hangout.
</p>
</template>