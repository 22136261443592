<script setup>
import projects from "@/content/Projects";
import { useRoute } from "vue-router";
import PictureCarousel from "../components/PictureCarousel";

const route = useRoute();
const project = projects[route.params.id];
</script>

<template>
  <header class="project-header">
    <h1>
      {{ project.title }}
      
    </h1>    
    <a v-if="project.repo_link" class="repo-icon" :href="project.repo_link" target="_blank">
        <i class="bi bi-github"></i>
    </a>
    <p v-if="project.contributors.length > 0">
      In collaboration with :
      <a
        v-for="(c, index) in project.contributors"
        v-bind:key="c.id"
        :href="c.url"
        target="_blank"
      >
        <span v-if="index > 0">, </span>
        {{ c.name }}
      </a>
    </p>
        <p>{{project.date}}</p>
    <ul
      class="container col-sm-8 d-flex flex-row justify-content-center flex-wrap techs"
    >
      <li v-for="tech in project.tech_stack" v-bind:key="tech.id">
        <img class="tech-icon" v-bind:src="tech.img" />
      </li>
    </ul>
  </header>
  <div class="container col-sm-12" v-if="project.pictures.length > 0">
      <PictureCarousel :pictures="project.pictures" />
  </div>
  <div class="container col-sm-4 col-md-4 col-lg-4">
    <h2>About the project</h2>
    <project.body v-if="project.body" />
    <h2>What I learned</h2>
    <ul
      class="skills"
    >
      <li v-for="skill in project.skills" :key="skill">
        {{ skill }}
      </li>
    </ul>
  </div>
</template>

<style scoped>
h1,
h2,
h3,
h4,
h5 {
  color: var(--secondary-color);
}

i {
  font-size: 1em;
}

a {
  color: var(--secondary-color);
  text-decoration: none;

  border-bottom: none;
  transition: all 0.1s ease-out 0s;
}

a:hover {
  color: var(--tertiary-color);
  border-bottom: none;
}

ul.techs li {
  list-style: none;
}

.tech-icon {
  height: 64px;
  margin: 10px;
}

.repo-icon{
  font-size: 2em;
  color: var(--primary-color);
}
.skills{
  margin: 0 3em 0 3em;
}

.skills li {
  text-align: left;
}

.carouselwrapper{
  width: 500px;
  height: 500px;
}
</style>
