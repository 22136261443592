<template>
<p>
This project was done as part of the Artificial Neural Networks course of my master's degree. With my colleagues Quentin and Rodrigues, we had to create a program
that would let us create and train single and multi layer perceptrons by implenting the learning process from scratch (without dedicated libraries such as TensorFlow for instance).
</p>
<p>
We chose C++ to have code that runs fast, and thus optimizing the learning time needed for the models. 
We also chose the Qt framework to set up a graphical user interface so that we could easily build and customize the learning models.
Thanks to the provided graph functions, we also implemented the display of our dataset with the decision lines that helps us understand how an artifical neural network enables us to classify the individuals in our dataset.
This program also monitors the value of the loss calculated at each epoch of our model, so that we can rest assured that our model is converging.
</p>
<p>
Here's an example of the decision lines being drawn for each iteration of the training.
</p>
<video class="col-12" controls>
    <source src="@/assets/rna/example.mp4" type="video/mp4">
</video>
<p>
This project gave us an even better understanding on what goes behind the facade of machine learning models and the mathematics involved.
</p>

</template>