<script setup>
import {defineProps, ref} from "vue";

const index = ref(0)
const props = defineProps(["pictures"])

const moveImgPtr = (value) => {
  const newValue = index.value + value;
  if ( newValue < 0){
    index.value = props.pictures.length + newValue;
  } else if ( props.pictures.length <= newValue){
    index.value = (props.pictures.length - newValue);
  }else{
    index.value = newValue;
  }
}

</script>

<template>
  <div class="container d-flex flex-row flex-wrap align-items-center justify-content-center skills">
    <div class="row d-flex flex-column flex-wrap align-items-center justify-content-center">
      <img :src="pictures[index % pictures.length].img" alt="img" class="carousel-pic">
      <span class="legend">{{pictures[index % pictures.length].legend}}</span>
    </div>
  </div>
<div class="container my-3 col-lg-8 col-md-10 d-flex flex-row flex-wrap align-items-center justify-content-center skills">
    <button @click="moveImgPtr(-1)" class="carousel-nav-btn previous">&lt;</button>
    <span>{{index + 1}} of {{pictures.length}}</span>
    <button @click="moveImgPtr(+1)" class="carousel-nav-btn next">&gt;</button>
  </div>
</template>

<style>
.carousel-nav-btn{
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  font-size: 2em;
  border: 2px solid transparent;
}

.carousel-nav-btn:hover{
  background-color: var(--background-color);
  border: 2px solid var(--secondary-color);
  color: var(--secondary-color);
}

.img-wrapper{
  height: 100%;
  
  width: 100%;
}

.skills li {
  text-align: left;
}

.legend{
  font-style: italic;
}

.carousel-pic{
  max-height: 650px;
}

@media (min-width: 800px){

.carousel-pic{
  height: 700px;
}
}


</style>