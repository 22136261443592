<template>
    
    <p>When I'm bored in class, I usually play Tetris, but, in order to do so, I need to access the website tetris.org.I didn't want to do that each time as I would have to load everything from outside of the school's network, and maybe one day the firewall could block it !</p>
    <p>So, there was only one solution left : build tetris myself so that I could make an executable for my linux environment.</p>
    <p>I chose Godot Engine for this project because I have a huge respect for open source projects, and it seemed like an easy segway to explore game development.</p>
    <p>I first started creating this project using regular 2D character classes and handling the collisions between pieces with the physics engine of Godot.</p>
    <p>However, in doing so, I greatly complicated the process of programming Tetris, I quickly understood that it was too much hassle for what seemed like simple mechanics</p>
    <p>I then stumbled upon <a class="link" target="_blank" href="https://www.youtube.com/watch?v=2T2Fkzwf6FM">this video of <i>Code with Russ</i></a> in which the author uses the TileMap node as a grid and then implements the Tetris mechanics with basic vector geometry</p>
    <p>It gave me the little help I needed to get unstuck and complete this project.</p>
</template>

<style scoped>
.link{
    color: var(--primary-color);
}

.link:hover{
    color: var(--secondary-color);
}
</style>