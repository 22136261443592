<template>
<p>My first OOP project with a UI, made with Java and C#.</p>

<p>It works with 2 desktop applications : </p>
<ul>
<li>The Planner : Java with JavaFX application that let's a project manager create tasks with dependencies, 
    assign them to the workers and then generate a PERT diagram that gives an estimate time before completion.
    The schedule is then serialized to a JSON file that is intended to be used by the Tracker
</li>
    <li>The Tracker : C# with Avalonia application which is used by the workers of the project to track and register the progress of their tasks.
    A login page prompts the user to authenticate, then the user can see their tasks and sort/filter through them. It also warns them when a task was expected to be done sooner and delays the whole project.
</li>
</ul>
</template>