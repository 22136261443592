<template>
<p>My colleague Loïck and I were tasked in our machine learning course to tinker with convolutional neural networks so that we could classify images of cats and dogs.
</p><p>
We first tried building our own model by using the TensorFlow keras library and we managed to get a somewhat good model. 
To do so, we first need to normalize ou dataset, which is make sure that all images have the same dimensions and the same brightness, 
the former so that all our images would fit into our input layer and the later so that our model wouldn't be biased by pictures that are too bright or too dark.
</p><p>
We combined convolution layers with poolings layers to extract the features that helps us make the difference between the picture of a dog and the picture of a cat. 
</p>
<p>
Then, we used the 'transfer learning' technique. We downloaded the Mobile Net v2 model, a public machine learning model that had already been trained for image classification
and fine tuned it to our dataset by replacing the last layer with out own.
</p>
</template>