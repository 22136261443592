import TetremzBody from "@/pages/projects/TetremzBody.vue";
import {
  godot,
  cpp,
  qt,
  react,
  flutter,
  dotnetcore,
  csharp,
  mysql,
  tf,
  python,
  ipynb,
  aspnet,
  avalonia,
  java,
  javafx,
  php,
  html,
  css,
  nodered,
  rpi,
  esp32,
  jira,
  gimp,
  sqlserver,
  entity,
} from "./Technologies";
import CatVsDogBody from "@/pages/projects/CatVsDogBody.vue";
import RNABody from "@/pages/projects/RNABody.vue";
import SecureVelockBody from "@/pages/projects/SecureVelockBody.vue";
import StudentLoungeBody from "@/pages/projects/StudentLoungeBody.vue";
import AdfgvxSptBody from "@/pages/projects/AdfgvxSptBody.vue";
import NoDebtBody from "@/pages/projects/NoDebtBody.vue";
import Plan2TrackBody from "@/pages/projects/Plan2TrackBody.vue";
import JitCompanyBody from "@/pages/projects/JitCompanyBody.vue";

class Contributor {
  constructor({ id, name, url } = {}) {
    this.id = id;
    this.name = name;
    this.url = url;
  }
}

class Project {
  constructor({
    id,
    title = "Title",
    description,
    thumbnail = "assets/belgium.png",
    tech_stack = [],
    tags = [],
    repo_link = "",
    paragraphs = [],
    body = null,
    pictures = [],
    sources = [],
    contributors = [],
    skills = [],
    date = ""
  } = {}) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.thumbnail = thumbnail;
    this.tech_stack = tech_stack;
    this.tags = tags;
    this.repo_link = repo_link;
    this.paragraphs = paragraphs;
    this.body = body;
    this.pictures = pictures;
    this.sources = sources;
    this.contributors = contributors;
    this.skills = skills;
    this.date = date;
  }
}

const kamal = new Contributor({
  id: "kamal",
  name: "Kamal AYADI",
  url: "https://www.linkedin.com/in/kamal-ayadi-1231b71b0",
});

const loick = new Contributor({
  id: "loick",
  name: "Loïck SALMON",
  url: "https://www.linkedin.com/in/lo%C3%AFck-salmon-33a270281/",
});

const rodrigues = new Contributor({
  id: "rodrigues",
  name: "Rodrigues LEJEUNE",
  url: "https://www.linkedin.com/in/rodrigues-lejeune/",
});

const theq = new Contributor({
  id: "quentin",
  name: "Quentin MELIS",
  url: "https://www.linkedin.com/in/quentin-melis-088636224/",
});

const tetremz = new Project({
  id: "tetremz",
  title: "Tetremz",
  description: "Recreating the famous Tetris puzzle game with Godot",
  thumbnail: require("../assets/tetremz/thumbnail.png"),
  tech_stack: [godot, gimp],
  tags: ["Game Dev"],
  repo_link: "https://github.com/Hendremy/Tetremzzz",
  body: TetremzBody,
  skills: [
    "How to create a game with Godot",
    "Using a TileMap as a grid to implement the game mechanics",
    "Object collisions",
    "Basic asset creation",
  ],
  pictures: [
    { 
      img : require("@/assets/tetremz/thumbnail.png"),
      legend: "In game example"
    },
    { 
      img : require("@/assets/tetremz/gameover.png"),
      legend: "Game over screen"
    },
  ],
  sources: [],
  date: 'July 2024'
});

const catvsdog = new Project({
  id: "catvsdog",
  title: "Cats vs Dogs",
  description:
    "Using CNNs and Transfer Learning to classify images of dogs and cats with TensorFlow",
  thumbnail: require("../assets/catvsdog/thumbnail.png"),
  tech_stack: [tf, python, ipynb],
  tags: ["Machine Learning"],
  repo_link: "https://github.com/salmon-loick/tf-projet-classification-image",
  sources: [
    "https://www.gulla.net/contentassets/56f8de40a4cb49bbad5d09d3e941be0e/catvsdog.png",
  ],
  body: CatVsDogBody,
  skills: [
    "Creating machine learning models with Keras",
    "Using CNNs to classify images",
    "Evaluate the quality of a model by looking at their accuracy and their loss",
    "Writing a Jupyter Notebook",
    "Using Transfer Learning for fine-tuning",
  ],
  pictures: [
    { 
      img : require("@/assets/catvsdog/thumbnail.png"),
      legend: "Dataset sample"
    },
    { 
      img : require("@/assets/catvsdog/loss-us.png"),
      legend: "Custom model loss"
    },
    { 
      img : require("@/assets/catvsdog/predict-us.png"),
      legend: "Custom model predictions"
    },
    { 
      img : require("@/assets/catvsdog/loss-transfer.png"),
      legend: "Transfer learning model loss"
    },
    { 
      img : require("@/assets/catvsdog/predict-transfer.png"),
      legend: "Transfer learning model predictions"
    },
    
  ],
  contributors: [loick],
  date: 'June 2024'
});

const rna = new Project({
  id: "rna",
  title: "Artificial Neural Networks",
  description:
    "C++ Qt app to build, train and monitor single layer and multi layer perceptrons",
  thumbnail: require("../assets/rna/thumbnail.webp"),
  repo_link: "https://github.com/abell370/RNA-Hendrice-Lejeune-Melis",
  tech_stack: [cpp, qt],
  body: RNABody,
  skills: [
    "Anatomy of an artificial neuron",
    "Synaptic weights retro-propagation",
    "Single-layer & Multi-layer perceptrons",
  ],
  pictures: [
    { 
      img : require("@/assets/rna/thumbnail.webp"),
      legend: "Multi-layer learning model used for classification of non linear separable data"
    },
    { 
      img : require("@/assets/rna/linear-reg.png"),
      legend: "Single-layer model used for linear regression"
    },
    { 
      img : require("@/assets/rna/mono-class.png"),
      legend: "Single-layer model used for linear separable data classification"
    },
  ],
  tags: ["Machine Learning"],
  contributors: [rodrigues, theq],
  date: 'from March through May 2024'
});

const securevelock = new Project({
  id: "securevelock",
  title: "Secure Vélock",
  description: "Prototype for safe and connected bike parking spots",
  thumbnail: require("../assets/securevelock/thumbnail.png"),
  tech_stack: [rpi, nodered, esp32],
  body: SecureVelockBody,
  skills: [
    "Using GrovePi sensors with a RaspberryPi",
    "Creating dashboards with NodeRED",
    "MQTT and LoRa to deliver sensor data",
  ],
  pictures: [
    { 
      img : require("@/assets/securevelock/thumbnail.png"),
      legend: "Architecture diagram"
    },
    { 
      img : require("@/assets/securevelock/rpi-1.png"),
      legend: "Station configuration dashboard"
    },
    { 
      img : require("@/assets/securevelock/rpi-2.png"),
      legend: "Station monitoring dashboard"
    },
    { 
      img : require("@/assets/securevelock/ubu-1.png"),
      legend: "Organisation monitoring dashboard"
    },
    { 
      img : require("@/assets/securevelock/ubu-2.png"),
      legend: "Map of stations"
    },
    { 
      img : require("@/assets/securevelock/ubu-3.png"),
      legend: "Sensor values & spot usage logs"
    },
    { 
      img : require("@/assets/securevelock/ubu-4.png"),
      legend: "Sensor configuration dashboard"
    },
  ],
  tags: ["IoT", "MQTT"],
  date: 'from October through December 2023'
});

const studentlounge = new Project({
  id: "studentlounge",
  title: "Student Lounge",
  description:
    "Web & mobile application for students to share course material and help each other",
  thumbnail: require("../assets/student-lounge/thumbnail.png"),
  repo_link: "https://github.com/Hendremy/StudentLounge",
  tech_stack: [react, flutter, csharp, dotnetcore, entity, sqlserver, jira],
  body: StudentLoungeBody,
  skills: [
    "REST APIs",
    "Authentication with JWTs",
    "Flutter using Bloc design pattern",
    "Project planning and management",
  ],
  pictures: [
    { 
      img : require("@/assets/student-lounge/thumbnail.png"),
      legend: "Log In screen"
    },
    { 
      img : require("@/assets/student-lounge/file-web-to-mob.webp"),
      legend: "File upload and download on web & mobile clients"
    },
    { 
      img : require("@/assets/student-lounge/calendar.webp"),
      legend: "Imported calendar with planned meeetings"
    },
    { 
      img : require("@/assets/student-lounge/mob-1.jpg"),
      legend: "Route to a meeting"
    },{ 
      img : require("@/assets/student-lounge/mob-2.jpg"),
      legend: "Calendar on mobile"
    },{ 
      img : require("@/assets/student-lounge/mob-3.jpg"),
      legend: "Shared files for a course"
    },{ 
      img : require("@/assets/student-lounge/mob-4.jpg"),
      legend: "Courses in which the user is enrolled"
    },{ 
      img : require("@/assets/student-lounge/mob-5.jpg"),
      legend: "Chat room"
    },
  ],
  tags: ["Web", "Mobile", "Full Stack"],
  contributors: [kamal],
  date: 'from October through December 2022'
});

const adfgvxspt = new Project({
  id: "adfgvxspt",
  title: "Adfgvx/Spanning-Tree",
  description:
    "Advgfx cipher optimization and solving mazes with spanning trees",
  thumbnail: require("../assets/adfgvx-spt/thumbnail.png"),
  repo_link: "https://github.com/Hendremy/Adfgvx-SpanningTree",
  tech_stack: [java],
  body: AdfgvxSptBody,
  skills: [
    "Optimizing the time and space complexity of an algorithm",
    "PRIM algorithm",
    "Using graph theory in programs",
  ],
  tags: ["Cryptography", "Mathematics", "Optimization"],
  date: 'May 2022'
});

const jitcompany = new Project({
  id: "jitcompany",
  title: "JustInTime Company",
  description: "Web app to book helicopter and plane flights",
  thumbnail: require("../assets/jit-company/thumbnail.png"),
  repo_link: "https://github.com/Hendremy/JustInTimeCompany",
  tech_stack: [csharp, aspnet, entity, sqlserver],
  body: JitCompanyBody,
  skills: [
    "MVC architecture for a web app",
    "Using an ORM Code-First",
    "Cybersecurity : prevent cross-site scripting, script/SQL injections, ...",
  ],
  tags: ["Web", "Booking"],
  date: 'July 2022'
});

const plan2track = new Project({
  id: "plan2track",
  title: "Plan2Track",
  description:
    "C# and Java desktop applications for project management using the PERT algorithm",
  thumbnail: require("../assets/p2t/thumbnail.jpg"),
  repo_link: "https://github.com/Hendremy/Plan2Track",
  tech_stack: [csharp, avalonia, java, javafx],
  body: Plan2TrackBody,
  skills: [
    "MVC and MVP design patterns",
    "JSON serialition/deserialization",
    "Create a UI for desktop programs",
    "PERT algorithm",
  ],
  tags: ["Desktop", "Planning"],
  date: 'from July to August 2022'
});

const nodebt = new Project({
  id: "nodebt",
  title: "No D€bt",
  description:
    "Vanilla php, HTML and CSS web application to manage group expenses",
  thumbnail: require("../assets/nodebt/thumbnail.png"),
  repo_link: "https://github.com/Hendremy/NoDebt",
  tech_stack: [php, html, css, mysql],
  body: NoDebtBody,
  skills: ["Dynamic website with php", "Styling a site with css"],
  tags: ["Web", "Finances"],
  date: 'from November 2021 through May 2022'
});

const projects = {
  tetremz,
  catvsdog,
  rna,
  securevelock,
  studentlounge,
  adfgvxspt,
  jitcompany,
  plan2track,
  nodebt,
};

export default projects;
