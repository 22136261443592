<template>
<p>A little Java project that was the subject of my Mathematics course exam where we learned about cryptography and graph theory.</p>

<p>My first task was to accurately implement the ADFGVX cipher and decipher algorithm while optimizing its space and time complexity. 
I did so by using VisualVM to track which methods used the most CPU time and RAM space and then making sure I use the right data structures.
</p>
<p>
My second mission was to implement a program that takes as input the picture of a black and white maze and then checks if the end is reachable from the start and that there is no loop.
To do so, I first scan the input image and translate it to a graph, where each node is a "room" and each edge symbolizes that two rooms are linked.
To check if the maze is solvable, I build a spanning tree using the PRIM algorithm from the start room and then check if the end room is included in that spanning tree.
Then, to detect potential loops, we simply need the formula "E - V + 1 > 0", where V is the number of nodes, E the number of edges. If it's true, then there are cycles.
</p>
</template>