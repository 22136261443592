class Technology {
  constructor({ key, name }) {
    this.key = key;
    this.name = name;
    this.img = require(`../assets/technologies/${key}.png`);
  }
}

const cpp = new Technology({
  key: "cpp",
  name: "C++",
});

const godot = new Technology({
  key: "godot",
  name: "Godot Engine",
});

const tf = new Technology({
  key: "tf",
  name: "TensorFlow",
});

const python = new Technology({
  key: "python",
  name: "Python",
});

const ipynb = new Technology({
  key: "ipynb",
  name: "Jupyter Notebook",
});

const qt = new Technology({
  key: "qt",
  name: "Qt",
});

const react = new Technology({
  key: "react",
  name: "React.js",
});

const flutter = new Technology({
  key: "flutter",
  name: "Flutter",
});

const dotnetcore = new Technology({
  key: "dotnetcore",
  name: ".NET Core",
});

const csharp = new Technology({
  key: "csharp",
  name: "C#",
});

const mysql = new Technology({
  key: "mysql",
  name: "MySQL",
});

const aspnet = new Technology({
  key: "aspnet",
  name: "ASP.NET",
});

const java = new Technology({
  key: "java",
  name: "Java",
});

const avalonia = new Technology({
  key: "avalonia",
  name: "Avalonia",
});

const javafx = new Technology({
  key: "javafx",
  name: "JavaFX",
});

const php = new Technology({
  key: "php",
  name: "PHP",
});

const css = new Technology({
  key: "css",
  name: "CSS",
});

const html = new Technology({
  key: "html",
  name: "HTML",
});

const rpi = new Technology({
  key: "rpi",
  name: "Raspberry Pi",
});

const esp32 = new Technology({
  key: "esp32",
  name: "ESP32",
});

const nodered = new Technology({
  key: "nodered",
  name: "Node-RED",
});

const jira = new Technology({
  key: "jira",
  name:"Jira"
})

const gimp = new Technology({
  key: "gimp",
  name:"GIMP"
})


const sqlserver = new Technology({
  key: "sql-server",
  name:"SQL Server"
})

const entity = new Technology({
  key: "entity",
  name:"Entity Framework"
})


export {
  cpp,
  godot,
  tf,
  python,
  ipynb,
  qt,
  react,
  flutter,
  aspnet,
  dotnetcore,
  csharp,
  mysql,
  java,
  avalonia,
  javafx,
  php,
  css,
  html,
  rpi,
  nodered,
  esp32,
  jira,
  gimp,
  sqlserver,
  entity,
};
