<template>
  <h1>Got Lost ?</h1>
  <iframe
    src="https://giphy.com/embed/BoAaI3YDNIbX00BMmW"
    width="480"
    height="274"
    style=""
    frameBorder="0"
    class="giphy-embed"
    allowFullScreen
  ></iframe>
  <p>
    <a
      href="https://giphy.com/gifs/car-the-office-michael-scott-BoAaI3YDNIbX00BMmW"
      >via GIPHY</a
    >
  </p>
</template>
