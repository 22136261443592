<template>
<p>The bicycle is becoming a more popular mode of transport, and to make sure that everyone can travel without fear of getting their bike stolen, I developed, for the IoT course of my master's degree,
a prototype of secure parking stations for bikes.</p>

<p>Each parking spot is equipped with a camera, a noise sensor and a vibration sensor to detect any fraudulent activity and alert the authorities in case of a possible theft attempt.
You can use your phone with NFC to lock your bike</p>

<p>
The parking spots are all linked to a station which serves as a hub. 
This hub collects data about the weather and shares its position so that users can easily locate it and find a parking spot that is available.
</p>
</template>