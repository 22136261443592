<script setup>
import MainHeader from "./components/MainHeader.vue";
//import MainFooter from "./components/MainFooter.vue";
import { watch, ref } from "vue";
import { useRoute } from "vue-router";

const showHeader = ref(false);
const route = useRoute();

watch(route, (to) => {
  showHeader.value = to.fullPath !== "/";
});
</script>

<template>
  <MainHeader v-if="showHeader" />
  <main class="">
    <RouterView />
  </main>
  <!--MainFooter /-->
</template>

<style>
:root {
  --text-primary: #d3c6aa!important;
  --text-secondary: #a7c080 !important;
  --primary-color: #dbbc7f !important;
  --secondary-color: #e69875 !important;
  --tertiary-color: #e67e80 !important;
  --background-color: #3c4841 !important;
  --background-color-90: rgb(60, 72, 65, 0.90) !important;
  --background-secondary-color: #232a2e !important;
  --background-secondary-color-90: rgba(35, 42, 46, 0.90) !important;
  --black-color: #000000 !important;
  --white-color: #ffffff !important;
  --success-color: #80b855 !important;
  --warning-color: #eaca44 !important;
  --error-color: #ef4d4d !important;
}

#app {
  font-family: "Exo 2", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-primary);
  background-color: var(--background-color);
}

main {
  min-height: 80vh;
  /*padding-left: 8vw;
  padding-right: 8vw;*/
  padding-bottom: 2vh;
  padding-top: 2vh;
  width: 100%;
  background-image: linear-gradient(var(--background-secondary-color-90),var(--background-secondary-color)), url("@/assets/bg.jpg");

}

html {
  background-color: var(--background-secondary-color);

  width: 100%;
}

body {
  width: 100%;

  /* background source: https://unsplash.com/photos/photography-of-tall-trees-at-daytime-MMJx78V7xS8?utm_content=creditShareLink&utm_medium=referral&utm_source=unsplash */


}
</style>
