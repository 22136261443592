import { createWebHistory, createRouter } from "vue-router";
import WelcomePage from "./pages/WelcomePage.vue";
import ResumePage from "./pages/ResumePage.vue";
import WildcardPage from "./pages/WildcardPage.vue";
import ProjectPage from "./pages/ProjectPage.vue";
import AllProjectsPage from "./pages/AllProjectsPage"

const routes = [
  {
    path: "/",
    component: WelcomePage,
  },
  {
    path: "/projects",
    component: AllProjectsPage
  },
  {
    path: "/projects/:id",
    component: ProjectPage,
  },
  {
    path: "/resume",
    component: ResumePage,
  },
  {
    path: "/:pathMatch(.*)*",
    component: WildcardPage,
  },
];

const router = createRouter({ history: createWebHistory(), routes });

export default router;
