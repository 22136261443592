<template>
  <ul class="d-flex align-self-center">
    <li>
      <a href="mailto:hendriceremy@gmail.com">
        <i class="bi bi-envelope"></i
      ></a>
    </li>
    <li>
      <a href="https://github.com/Hendremy" target="_blank">
        <i class="bi bi-github"></i>
      </a>
    </li>
    <li>
      <a
        href="https://www.linkedin.com/in/r%C3%A9my-hendric%C3%A9-326b7b1ab/"
        target="_blank"
      >
        <i class="bi bi-linkedin"></i>
      </a>
    </li>
  </ul>
</template>

<style scoped>
i {
  font-size: 2em;
}

a {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}

li a {
  color: var(--primary-color);
  text-decoration: none;

  border-bottom: none;
  transition: all 0.1s ease-out 0s;
}

li a:hover {
  color: var(--tertiary-color);
}

li a:hover {
  border-bottom: none;
}

ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 100%;
}

li {
  list-style: none;
  margin: 0 8px 0 8px;
  padding: 0;
}
</style>
